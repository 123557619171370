import React from 'react'
import Link from 'gatsby-link'

const NavigationInterior = () => {
	return (
		<div className="navigation-interior">
      <div className="core-links">
        <Link className="core-links-internal" to="/">home</Link>
        <Link to="/writing/page/1" className="core-links-internal">writing</Link>
        <Link to="/about" className="core-links-internal">about</Link>
        <Link to="/press/page/1" className="core-links-internal">press</Link>
      </div>
    </div> 
	)
}

export default NavigationInterior