import React, { Component } from 'react'
import { graphql } from 'gatsby'
import propTypes from 'prop-types'
import Footer from '../components/footer'
import NavigationInterior from '../components/navigation-interior'

class BlogPost extends Component {
    render() {
        const postBody = {__html: this.props.data.contentfulPost.childContentfulPostPostBodyTextNode.childMarkdownRemark.html };
        const postBodySecond = this.props.data.contentfulPost.childContentfulPostPostBodySecondTextNode ? {__html: this.props.data.contentfulPost.childContentfulPostPostBodySecondTextNode.childMarkdownRemark.html }: null;
        const { title, createdAt, imageLink } = this.props.data.contentfulPost
        return (
                <div key="one" className="single-post generic-container blog-post-container">
                    <div key="two"  className="single-post-inner">
                        <div key="three"  className="post-body-text">
                            <div key="four" className="post-body-text-internal">
                                <h3 key="post-title" className="post-title">
                                    {title}
                                </h3>
                                <p key="created-at-date">{createdAt}</p>
                            </div>
                        </div>
                         <div key="centered-img" className="centered-img-container">
                            <img src={imageLink.fluid.src} alt="post-img"/>
                        </div>
                        <div key="five" className="post-body-text">
                            <div key="post_body_one" className="post-body-text-internal" dangerouslySetInnerHTML={postBody}/>
                            <hr className="post-body-divider"></hr>
                            <div key="post_body_two" className="post-body-text-internal" dangerouslySetInnerHTML={postBodySecond}/>
                        </div>
                    </div>
                    <NavigationInterior></NavigationInterior>  
                    <Footer></Footer>
                </div>
        )
    }
}

BlogPost.propTypes = {
    data: propTypes.object.isRequired
}

export default BlogPost

export const pageQuery = graphql`
    query blogPostQuery($slug: String!){
        contentfulPost(slug: {eq: $slug}) {
            title
            createdAt(formatString: "MMMM DD, YYYY")
            childContentfulPostPostBodyTextNode {
              childMarkdownRemark {
                html
              }
            }
            childContentfulPostPostBodySecondTextNode {
                childMarkdownRemark {
                    html
                }
            }
            imageLink {
                fluid (quality: 100) {
                    src
                }
            } 
        }
    }
`